const theme = {
    styles: {
        h1: {
          variant: 'text.heading',
          fontFamily: 'heading',
          fontWeight: 'heading',
          color: 'primary',
          mt: 4,
          mb: 2,
          fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
        },
        a: {
          color: 'primary',
          '&:hover': {
            background: '#c1a82f',
            color: '#000',
          }
        },
          cards: {
            primary: {
              padding: 2,
              borderRadius: 4,
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
            },
            compact: {
              padding: 1,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'muted',
            },
          },
        buttons: {
          primary: {
            color: 'background',
            bg: 'primary',
            '&:hover': {
              bg: 'yellow',
            }
          }
        },
        Message: {
          primary: {
            color: 'background',
            bg: 'primary',
            '&:hover': {
              bg: 'yellow',
            }
          }
        },
        root: {
          fontFamily: 'body',
          fontWeight: 'body',
          lineHeight: 'body',
        },
        p: {
          fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
        },
        h2: {
          variant: 'text.heading',
          fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
        },
        pre: {
          fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
          backgroundColor: "none"
        }
      },
    
      fonts: {
        body: "system-ui, sans-serif",
        heading: "system-ui, sans-serif",
        monospace: "Menlo, monospace",
      },
      fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
      },
      lineHeights: {
        body: 1.5,
        heading: 1.125,
      },
      fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
      space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
      links: {
        nav: {
          textTransform: 'uppercase',
          letterSpacing: '0.04em',
          textAlign: 'center',
        }
      },
      
    
  }
  
  export default theme