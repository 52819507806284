// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-essays-js": () => import("./../../../src/pages/essays.js" /* webpackChunkName: "component---src-pages-essays-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ron-stories-js": () => import("./../../../src/pages/ronStories.js" /* webpackChunkName: "component---src-pages-ron-stories-js" */),
  "component---src-templates-book-post-js": () => import("./../../../src/templates/book-post.js" /* webpackChunkName: "component---src-templates-book-post-js" */),
  "component---src-templates-essay-post-js": () => import("./../../../src/templates/essay-post.js" /* webpackChunkName: "component---src-templates-essay-post-js" */),
  "component---src-templates-ron-post-js": () => import("./../../../src/templates/ron-post.js" /* webpackChunkName: "component---src-templates-ron-post-js" */)
}

